import React from 'react';
import Layout from '../../components/Layout';
import Link from 'gatsby-link';

// photos
import family1 from './family1.jpg';
import family2 from './family2.png';
import family3 from './family3.png';

export default () => {
  return (
    <Layout>
      <div className="content">
        <main className="about">
          <div className="container">
            <div className="about__image">
              <img src={family1} alt="Brown Family" />
            </div>
          </div>
          <div className="single-content">
            <div className="container">
              <h1 className="mb0">Dan Brown</h1>
              <h3>Father / Husband / Creator</h3>
              <p>
                My name is Dan and I currently live in sunny San Diego with my
                wife and son. I can usually be found{' '}
                <Link to="/experience">brainstorming&nbsp;on a whiteboard</Link>,
                working on one of my crazy side-projects, throwing back a{' '}
                <a href="https://untappd.com/user/Slightlyoffbeat">cold one</a>{' '}
                at a local brewery, or enjoying the beach with family.
              </p>
              <img
                className="size-large"
                src={family2}
                alt="Brown Family 2"
                width="1200"
                height="500"
              />
              <p>
                In my free time, I read, run, brew, bike, learn, code, build,
                hammer, explore, surf, saw, drum, travel, strum, hike, jam,
                cook, design, and chill.
              </p>
              <p>
                Want to learn more about my work experience? Check out my{' '}
                <Link to="/experience">experience page</Link>.
              </p>
              <img
                className="size-large"
                src={family3}
                alt="Brown Family 3"
                width="1200"
                height="600"
              />
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};
